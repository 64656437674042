import { Component } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from './api.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentPageTitle = 'Dashboard';

  appPages = [
    {
      title: 'Dashboard',
      url: '',
    },
  ];
  constructor(public nav:NavController,public api:ApiService,public alertController:AlertController) {
    if(localStorage.getItem('profile')){
      this.nav.navigateRoot('home-partner');
      console.log( JSON.parse( localStorage.getItem( 'profile') ) );
    }else{
      this.nav.navigateRoot('login');
    }
   //this.nav.navigateRoot('new-register');
  }
  dashboard(){
    this.nav.navigateRoot('home-partner');
  }
  studentRegister(){
    this.nav.navigateRoot('student-register');
  }
  newRegister(){
    this.nav.navigateRoot('new-register');
  }
  approveStudent(){
    this.nav.navigateRoot('approved-students');
  }
  disApproveStudent(){
    this.nav.navigateRoot('disapprove-students');
  }
  addMaterials(){
    this.nav.navigateRoot('add-materials');
  }
  addQuestions(){
    this.nav.navigateRoot('add-questions');
  }
  testResults(){
    this.nav.navigateRoot('test-results');
  }
  profile(){
    this.nav.navigateRoot('profile');
  }
  async logout(){
    
    const alert = await this.alertController.create({
      header: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {  }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            localStorage.clear();
            this.api.showMenu=true;
            this.nav.navigateRoot('login');
          }
        }
      ]
    });

    await alert.present();
    
  }
}
